import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Book from "../components/reading"

const Books = (props) => {

    const books = props.data.books.nodes

    return (
        <Layout>
        <SEO title="Books" />
        <h1 className="mb-5 mt-12 plane text-center">Books</h1>
        <section className="grid grid-cols-1 md:grid-cols-2">
        {books.map(book => <Book key={book.frontmatter.title} book={book} />)}
        </section>
    </Layout>

    )
}

export default Books


export const pageQuery = graphql`
    query {
        books: allMdx(filter: { fields: {slug: {regex: "/books/"}}}) {
            nodes {
            frontmatter {
                author
                progress
                title
                amazon
                image {
                    childImageSharp {
                        fluid {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            fields {
                slug
            }
            }
        }
   }
`